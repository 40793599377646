import { graphql } from "gatsby";
import { navigate } from "@reach/router";
import React, {useEffect} from "react";
import { Helmet } from "react-helmet";
import notFoundImage from "../content/images/not-found-image.svg";
import { useGlobalState } from "../state/globalState";

const regex = /\/en\/our-partners\/(?:partners|offer)\/([0-9a-z]+)\/?$/;

export default () => {
  const [headerOptions, setHeaderOptions] = useGlobalState("headerOptions");

  useEffect(() => {
    setHeaderOptions({
      ...headerOptions,
      colorScheme: "light",
      hidden: true,
    });
  }, []);

  useEffect(() => {
      if (typeof window === `undefined`) {
          return;
      }
      if (!regex.test(window.location.pathname)) {
          return;
      }
      const match = regex.exec(window.location.pathname);
      if (!match) {
          return;
      }
      const lowercaseID = match[1];
      if (!lowercaseID) {
          return;
      }
      navigate(window.location.pathname.replace(lowercaseID, lowercaseID.toUpperCase()), {
          replace: true,
      });
  }, []);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <main className="flex flex-col justify-around flex-grow max-w-4xl mx-auto">
        <img className="p-4" src={notFoundImage} alt="Page not found" />
      </main>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
